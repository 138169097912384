import React, {useEffect, useState} from "react";
import {Chart} from "react-google-charts"
import {Container, Header,} from "@amzn/awsui-components-react";
import "./GaugeChart.scss"

function getRandomNumber() {
    return Math.random() * 100;
}

export function getData() {
    return [
        ["Label", "Value"],
        ["Memory", getRandomNumber()],
        ["CPU", getRandomNumber()],
        ["Network", getRandomNumber()],
        ["DiskUsage", getRandomNumber()],


    ];
}

export const options = {
    width: 250,
    height: 300,
    redFrom: 90,
    redTo: 100,
    yellowFrom: 75,
    yellowTo: 90,
    minorTicks: 5,
};

export function GaugeChart() {
    const [data, setData] = useState(getData);

    useEffect(() => {
        const id = setInterval(() => {
            setData(getData());
        }, 3000);

        return () => {
            clearInterval(id);
        };
    });
    return (
        <div className="justify-content-center">
            <Container header={<Header variant="h2">Gauge Chart</Header>}>
                <div className="guage">
                    <Chart
                        chartType="Gauge"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />

                </div>
            </Container>
        </div>

    )
}