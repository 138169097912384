import React, {useState} from 'react'
import {useParams} from "react-router-dom";
import "./userEngagement.scss"
import {Button, Container, Header, TextContent} from "@amzn/awsui-components-react-v3";
import {UsersEngaged} from "../../tickets-api/generated-src";
import TicketsApiFactory from "../../tickets-api/TicketsApiFactory";
import Input from "@amzn/awsui-components-react/polaris/input";


export function GetUserEngagement(props) {

    // Initialize the state of our items to null to indicate we've not yet fetched them.
    const [setRules] = useState('');

    const {ticketId} = useParams<{ ticketId: string }>();
    const [loading, setLoading] = useState(false);

    const [userName, setValue] = React.useState('');

    const handleChange = (e: any) => {
        e.preventDefault();
        setValue(e.detail.value);
    }
    const TicketsApi = TicketsApiFactory();


    const engageUsers = async () => {
        // Note that you can use any library you like for making AJAX requests. In some cases,
        // you may need to specify a 'Content-Type' header of 'application/json'.
        if (typeof userName != 'undefined' && userName) {
            let tickett: UsersEngaged = {
                "ticket": ticketId,
                "user": userName
            }
            const response = (await TicketsApi.engageuserById(tickett)).data
            if (response == null) {
                alert(`User @${userName} has been paged`)
            }
            setLoading(false);
        } else {
            alert(`Please enter a valid user alias`);
        }
    };

    const content = <div>
        <Container className="onboarding-container" header={<Header variant="h2">User Engagement</Header>} fitHeight>
            <div className={"user-engagement"}>
                <section>
                    <div className="textContentStyle">
                        <div>
                            <TextContent><strong> UserName : </strong></TextContent>
                        </div>
                        <div className="textInputStyle">
                            <Input onChange={handleChange} value={userName} readOnly={false}
                                   placeholder={"Username Alias"}
                                   autoFocus={true}/>
                        </div>
                        <br/>
                    </div>

                    <div>
                        <Button onClick={engageUsers} variant="primary" ariaLabel="Get" loading={loading}>Page</Button>
                    </div>
                    <br/>
                </section>
            </div>
        </Container>
    </div>;

    return content;

}