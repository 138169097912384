import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import { ColumnLayout, Container, Grid } from "@amzn/awsui-components-react";
import { Ticket } from "../../tickets-api/generated-src";
import MitigationLevers from "./mitigationLever/MitigationLevers";

export function Recommendation(props) {
  /**
   * Component to render recommendations
   */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const allSourceItems: Ticket[] = props.data || [];
  const updateWorkFlows = props.updateWorkFlows;
  const recommendationItems = allSourceItems.filter((item) => {
    if ("recommendedServices" in item) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <>
      {/*{recommendationItems.length > 0 &&*/}
      {/*  recommendationItems.map((item) => {*/}
      {/*    if (*/}
      {/*      item.recommendedServices.length == 0 &&*/}
      {/*      item.recommendedRootCauses.length == 0*/}
      {/*    ) {*/}
      {/*      return <></>;*/}
      {/*    } else {*/}
      {/*      return (*/}
              <Container
                header={
                  <div id="headerMainDiv">
                    <div>
                    <Header
                      variant="h2"
                      description=""
                    >
                      Inferences
                    </Header>
                      </div>
                    <div id="mitigationHeader">
                    <Header variant="h2">Mitigation Levers</Header>
                    </div>
                  </div>
                }
              >
                <div id="parentFlex">
                  <div id="recommendationsDiv">
                    <div style={{fontWeight:"bold"}}>Historical Data</div>
                    <div>3P Skill has 90% of affecting the service</div>
                    <div>RemoteApplicationFramework has 60% affecting the service</div>
                    <div>Gameday has 50% of affecting the service</div>
                    <br/>
                    <div style={{fontWeight:"bold"}}>Atocha</div>
                    <div>"DeviceStateNotFoundException-get@ADS3StateStore.java:118": 381739</div>
                    <div>"ConversionException-getConverterTo@Unit.java:193": 55068</div>
                    <br/>
                    <div style={{fontWeight:"bold"}}>Infrastructure</div>
                    <div>Cpu utilisation is high by 10% in ACRS</div>
                    <div>Traffic surge increase in AOS by 15%</div>
                    <br/>
                    <div style={{fontWeight:"bold"}}>Heat Map</div>
                    <div>Disk usage is high by 40% for efgh-fe-p-1c-m5-2xl-efbc9455.us-west-2.amazon.com host</div>



                    {/*<span>*/}
                    {/*  {item.recommendedServices.map((service) => {*/}
                    {/*    return (*/}
                    {/*      <div>*/}
                    {/*        Based on historical data {service} has very high*/}
                    {/*        chances of affecting the service*/}
                    {/*      </div>*/}
                    {/*    );*/}
                    {/*  })}*/}
                    {/*  </span>*/}
                    {/*  <span>*/}
                    {/*    {item.recommendedRootCauses.map((rootcause) => {*/}
                    {/*      return (*/}
                    {/*        <div>*/}
                    {/*          Based on historical data {rootcause} has very high*/}
                    {/*          chances of affecting the service*/}
                    {/*        </div>*/}
                    {/*      );*/}
                    {/*    })}*/}

                    {/*</span>*/}
                  </div>
                  <div id="lineSeparate"/>
                  <div id="mitigationLeverDiv">
                    <MitigationLevers updateWorkFlows={updateWorkFlows}/>
                  </div>
                </div>
              </Container>
            {/*);*/}
        {/*  }*/}
        {/*})}*/}
    </>
  );
}
