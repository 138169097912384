import React, {useEffect, useState} from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import {GetUserEngagement} from "./userEngagement";
import {CreateTicketFunction} from "./TicketCreation";
import Header from "@amzn/awsui-components-react/polaris/header";
import Navigation from "../SideNavigation";
import {Aggregation, Ticket, Workflow} from "../../tickets-api/generated-src";
import "./DashboardGridLayout.scss";
import TicketsApiFactory from "../../tickets-api/TicketsApiFactory";
import WorkflowsExecuted from "./WorkflowsExecuted";
import {Recommendation} from "./Recommendation";
import {ControlledCarousel} from "./Carousel";
import {PostComment} from "./mitigationLever/ticketOperations/postComment";
import {DataSources} from "./DataSources";

// replace each grid with respective component. for now added datasourcecard component for all 5 grids
export function DashboardGridLayout(props) {
    /*
      Dashboard component containing datasource cards, graphed anomalies, timeline and recommendations
    */

    const allSourceItems: Ticket[] = props.ticketItems;
    const ticketId = props.ticketId;
    const TicketsApi = TicketsApiFactory();
    const [allWorkflows, setAllWorkflows] = useState<Workflow[]>();
    const [aggregationData, setAggregationData] = useState<Aggregation>();
    const [showWorkFlow, setShowWorkFlow] = useState(false);

    useEffect(() => {
        void (async () => {
            setAllWorkflows((await TicketsApi.getWorkflowsByTicketId(ticketId)).data);
        })();
    }, [ticketId]);

    useEffect(() => {
            void (async () => {
                setAggregationData((await TicketsApi.getAggregationsByTicketId(ticketId)).data);
            })();
        }, [ticketId]);

    console.log(aggregationData)

    const updateWorkFlows = ()=>{
        setShowWorkFlow(true);
    }

    return (

        <ContentLayout
            header={<Header variant="h1">TicketId: {props.ticketId}</Header>}
        >
            <Grid
                gridDefinition={[{colspan: 4}, {colspan: 4}, {colspan: 4}]}
            >
                <div id="datasources">
                    <DataSources/>
                </div>
                <div id="workflows">
                    <WorkflowsExecuted data={allWorkflows} showAtochaworkFlow={showWorkFlow}/>
                </div>
                <div id="carousel">
                    <ControlledCarousel data={aggregationData}/>
                </div>
            </Grid>
            <Grid
                gridDefinition={[{colspan: 8}, {colspan: 4}]}
            >
                <div>
                    <Grid
                        gridDefinition={[{colspan: 6}, {colspan: 6}]}
                    >
                        <div id="engage">
                            <GetUserEngagement data={allSourceItems}/>
                        </div>
                        <div id="comment">
                            <PostComment/>
                        </div>
                    </Grid>
                    <Grid gridDefinition={[{colspan: 12}]}>
                        <div id="recommendation">
                            <Recommendation data={allSourceItems} updateWorkFlows={updateWorkFlows}/>
                        </div>
                        <br/>
                    </Grid>
                </div>
                <div id="ticket">
                    <CreateTicketFunction data={allSourceItems}/>
                </div>
            </Grid>


        </ContentLayout>
    );
}
