
import React, {useState} from 'react'
import { useParams } from "react-router-dom";
import {
  Button,
  Container,
  Header, TextContent
} from "@amzn/awsui-components-react-v3";
import { CreateTicket} from "../../tickets-api/TicketsApiFactory";
import Input from "@amzn/awsui-components-react/polaris/input";


export function CreateTicketFunction(props) {

    // Initialize the state of our items to null to indicate we've not yet fetched them.

  const { ticketId } = useParams<{ ticketId: string }>();
  const [loading, setLoading] = useState(false);
    
    const [category, setCategory] = React.useState('');
    const [type, setType] = React.useState('');
    const [item, setItem] = React.useState('');
    const [description, setDescription] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [severity, setSeverity] = React.useState('')

    const ticketCreationApi = CreateTicket();

  const cutTicket = async () => {
    // Note that you can use any library you like for making AJAX requests. In some cases,
    // you may need to specify a 'Content-Type' header of 'application/json'.
    if (typeof category != 'undefined' && category &&
        typeof type != 'undefined' && type &&
        typeof item != 'undefined' && item &&
        typeof description != 'undefined' && description &&
        typeof title != 'undefined' && title &&
        typeof severity != 'undefined' && severity ) {
            console.log("Inside aync call")
      const response = (await ticketCreationApi.createTicket({
            "category" : ticketId,
            "type": type,
            "item": item,
            "severity": severity,
            "title": title,
            "description": description
      })).data
      if (response == null) {
        alert(`Ticket has been created`)
      } 
      setLoading(true);
    } else {
      alert(`Please enter all the required fields`);
    }
  };

  const content = <div>
  <Container className="onboarding-container" header={<Header variant="h2">Create Ticket</Header>} fitHeight>
    <section>
      <div className="textContentStyle">
        <div>
          <TextContent><strong> Category : </strong></TextContent>
        </div>
        <div className="textInputStyle">
          <Input 
            onChange={(event) => setCategory(event.detail.value)} 
            value={category} 
            readOnly={false} 
            placeholder={"Category"}
            autoFocus={true}/>
        </div>
        <br/>
        <div>
          <TextContent><strong> Type : </strong></TextContent>
        </div>
        <div className="textInputStyle">
          <Input 
            onChange={(event) => setType(event.detail.value)} 
            value={type} 
            readOnly={false} 
            placeholder={"Type"}
            autoFocus={true}/>
        </div>
        <br/>
        <div>
          <TextContent><strong> Item : </strong></TextContent>
        </div>
        <div className="textInputStyle">
          <Input 
            onChange={(event) => setItem(event.detail.value)} 
            value={item}
            readOnly={false} 
            placeholder={"Item"}
            autoFocus={true}/>
        </div>
        <br/>
      </div>
      <div>
          <TextContent><strong> Severity : </strong></TextContent>
        </div>
        <div className="textInputStyle">
          <Input 
            onChange={(event) => setSeverity(event.detail.value)} 
            value={severity}
            readOnly={false} 
            placeholder={"Severity"}
            autoFocus={true}/>
        </div>
      <br/>
      <div>
          <TextContent><strong> Title : </strong></TextContent>
        </div>
        <div className="textInputStyle">
          <Input 
            onChange={(event) => setTitle(event.detail.value)} 
            value={title}
            readOnly={false} 
            placeholder={"Title"}
            autoFocus={true}/>
        </div>
      <br/>
      <div><TextContent><strong>Description</strong></TextContent></div>
      <textarea
        className="form-control markdown-textarea"
        rows={3}
        value={description}
        onChange={(event) => setDescription(event.target.value)} 
      ></textarea>
      <br/>
      <div>
        <Button onClick={cutTicket}  variant="primary" ariaLabel="Get" loading={loading}>Create Ticket</Button>
      </div>
      <br/>
    </section>
  </Container>
</div>;

return content;

}