import Header from "@amzn/awsui-components-react/polaris/header";
import React from "react";
import {Box, Container, Icon, SpaceBetween} from "@amzn/awsui-components-react";
import {Chart} from "react-google-charts";

export default function SankeyTimeline(props) {

  const updateToolBoxContent = props.updateToolboxContent;

  const sankeyDataJsonString =
    "{\n" +
    '    "ACRS": {\n' +
    '        "ticketIds": [],\n' +
    '        "mentionedServices":  \n' +
    "            {\n" +
    '                "AOGS": "2",\n' +
    '                "PAMS": "3"\n' +
    "            }\n" +
    "    },\n" +
    '    "AOS": {\n' +
    '        "ticketIds": [],\n' +
    '        "mentionedServices":  \n' +
    "            {\n" +
    '                "AOGS": "2",\n' +
    '                "PAMS": "3"\n' +
    "            }\n" +
    "    }\n" +
    "}";

  let sankeyDataJson = JSON.parse(sankeyDataJsonString);
  let sankeyData: (string | number)[][] = [["From", "To", "Weight"]];
  for (let service in sankeyDataJson) {
    for (let mentionedService in sankeyDataJson[service].mentionedServices) {
      sankeyData.push([
        service,
        mentionedService,
        parseInt(sankeyDataJson[service].mentionedServices[mentionedService]),
      ]);
    }
  }

  const options = {
    width: 800,
    height: 400,
  };

  const sankeyHelpContent = <div className={"toolbox-parent-box"}>
    <Header>🔀 Sankey Timeline</Header>
    <br/>
    <Box variant={"div"}>
      <br/>
      <Box variant={"h5"} fontWeight={"bold"}>🎟️ Description</Box>
      <SpaceBetween size={"s"} direction={"vertical"}>
        <div>
          Sankey diagram represents the number of times a service is mentioned in the
          correspondence of a ticket for another service. This helps understand the services
          relationships among ongoing service Sev2s.
          <br/>
          While reading from left to right, left side represents the services with Sev2,
          having connections with the services in the right, where each connection has a weight
          equal to the number of times the service in mentioned in its Sev2's correspondence.
        </div>
      </SpaceBetween>
    </Box>
  </div>

  function openToolbox() {
    updateToolBoxContent(sankeyHelpContent);
  }

  return (
    <Container
      header={
        <Header
          variant="h2"
          description="Below shown are the weight refer to the number of times the services are mentioned in correspondence section."
        >
          <SpaceBetween size={"s"} direction={"horizontal"}>
            <div>
              Sankey Timeline
            </div>
            <div onClick={openToolbox}>
              <Icon name="status-info"/>
            </div>
          </SpaceBetween>
        </Header>
      }
    >
      <div>
        <Chart
          chartType="Sankey"
          width="100%"
          data={sankeyData}
          options={options}
        />
      </div>
    </Container>
  );
}
