import React, {Ref, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import TicketsApiFactory from "../../tickets-api/TicketsApiFactory";
import { Ticket } from "../../tickets-api/generated-src";
import EventsTimeline from "./eventsTimeline/EventsTimeline";
import { DashboardGridLayout } from "./DashboardGridLayout";
import "./DashboardHome.scss";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import SankeyTimeline from "./SankeyTimeline";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Navigation from "../SideNavigation";

export default function DashboardHome() {
  /*
    Dashboard component containing datasource cards, graphed anomalies, timeline and recommendations
  */

  const { ticketId } = useParams<{ ticketId: string }>();
  const [allItems, setAllItems] = useState<any>();
  const TicketsApi = TicketsApiFactory();
  const [toolboxContent, setToolboxContent] = useState<React.Component>();
  const [selectedTab, setSelectedTab] = useState<string>("second");
  // const [appLayoutRef, setAppLayoutRef] = useState<any>();

  let appLayoutRef: Ref<any> | null = null;

  useEffect(() => {
    void (async () => {
      setAllItems((await TicketsApi.showTicketById(ticketId)).data);
    })();
  }, [ticketId]);

  function updateToolboxContent(content: React.Component) {
    setToolboxContent(content);
    if (appLayoutRef) {
        // @ts-ignore
        appLayoutRef.openTools();
    }
  }


  const allSourceItems: Ticket[] = allItems || [];
  return (
      <AppLayout
          navigation={<Navigation/>}
          id="Dashboard"
          tools={toolboxContent}
          ref={(ref) => { // @ts-ignore
              appLayoutRef = ref}}
          content={
            <Tabs
                onChange={event => {console.log(event); setSelectedTab(event.detail.activeTabId)}}
                activeTabId={selectedTab}
                tabs={[
                  {
                    label: "Dashboard",
                    id: "first",
                    content: (
                        <DashboardGridLayout
                            ticketItems={allSourceItems}
                            ticketId={ticketId}
                        />
                    ),
                  },
                  {
                    label: "Events Timeline",
                    id: "second",
                    content: (
                        <ContentLayout>
                          <EventsTimeline
                              ticketItems={allSourceItems}
                              updateToolboxContent={updateToolboxContent}
                          />
                          <br />
                          <SankeyTimeline data={"DummyPlaceHolderData"}
                                          updateToolboxContent={updateToolboxContent}
                          />
                        </ContentLayout>
                    ),
                  },
                ]}
                id="tabs"
            />
          }
      />
  );
}
