import React, {useState} from 'react';
import {GaugeChart} from "./charts/GaugeChart";
import {Container, Header, Spinner} from "@amzn/awsui-components-react";
import {Aggregation} from "../../tickets-api/generated-src";

export function ControlledCarousel(props) {

    const aggregation: Aggregation = props.data

    return (
        <div>
            {aggregation ?
                <div id="carouselIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselIndicators" data-slide-to="2"></li>
                    </ol>

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <Container id="atocha" header={<Header variant="h2">Atocha Aggregation</Header>}>
                                <a href={aggregation.atocha ? aggregation.atocha.data : ""} target="_blank">
                                    <img className="d-block w-100"
                                         src={aggregation.atocha ? aggregation.atocha.data : ""}
                                         alt="Atocha slide" height="250"/>
                                </a>
                            </Container>
                        </div>

                        <div className="carousel-item gaugeChart">
                            <GaugeChart/>
                        </div>
                        <div className="carousel-item">
                            <Container header={<Header variant="h2">AOS : CPU_UTILISATION</Header>}>
                                <a href={aggregation.pmet ? aggregation.pmet.data : ""} target="_blank">
                                    <img className="d-block w-100"
                                         src={aggregation.pmet ? aggregation.pmet.data : ""}
                                         alt="PMET slide"
                                         height="250"/>
                                </a>
                            </Container>
                        </div>
                        <div className="carousel-item">
                            <Container header={<Header variant="h2">Instance Level HeatMap</Header>}>
                                <a href={aggregation.heatmap ? aggregation.heatmap.data : ""} target="_blank">
                                    <img className="d-block w-100"
                                         src={aggregation.heatmap ? aggregation.heatmap.data : ""}
                                         alt="HeatMap slide"
                                         height="250"/>
                                </a>
                            </Container>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                :
                <Spinner size="normal"/>
            }
        </div>

    );
}