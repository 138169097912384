import * as React from "react";
import {Container, Header} from "@amzn/awsui-components-react";
import {Link} from "react-router-dom";
import "./DataSources.scss"

const ticketId = "a383d86d-fdeb-41b9-8352-8284b6485c34"

export function DataSources() {
    /*
       Dummy Data for DataSourcesCards Component
      */
    return (
        <>
            <Container header={<Header variant="h2">DataSources</Header>}>
                <div className={"datasources"}>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/`}
                        >
                            <li style={{color: "red"}}>
                                4 Ongoing reactive insights on System Health
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/APOLLO`}
                        >
                            <li style={{color: "green"}}>
                                5 Ongoing reactive insights on Apollo
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/LSE`}
                        >
                            <li style={{color: "green"}}>
                                2 Ongoing reactive insights on LSE
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/DISK_USAGE`}
                        >
                            <li style={{color: "red"}}>
                                4 Ongoing reactive insights on DISK_USAGE
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/MCM`}
                        >
                            <li style={{color: "green"}}>
                                4 Ongoing reactive insights on MCM
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/CPU_UTILISATION`}
                        >
                            <li style={{color: "red"}}>
                                10 Ongoing reactive insights on CPU_UTILISATION
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/MEM_ACTIVE`}
                        >
                            <li style={{color: "green"}}>
                                1 Ongoing reactive insights on MEM_ACTIVE
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/SEV2`}
                        >
                            <li style={{color: "red"}}>
                                4 Ongoing reactive insights on SEV2
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/THROTTLING`}
                        >
                            <li style={{color: "red"}}>
                                2 Ongoing reactive insights on THROTTLING
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/TRAFFIC_SURGE`}
                        >
                            <li style={{color: "green"}}>
                                10 Ongoing reactive insights on TRAFFIC_SURGE
                            </li>
                        </Link>
                    </div>
                    <div>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/${ticketId}/WEBLABS`}
                        >
                            <li style={{color: "green"}}>
                                5 Ongoing reactive insights on WEBLABS
                            </li>
                        </Link>
                    </div>
                </div>
            </Container>
        </>
    )
}
