/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI InsightsData
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Aggregation
 */
export interface Aggregation {
    /**
     * 
     * @type {AggregationAtocha}
     * @memberof Aggregation
     */
    'atocha'?: AggregationAtocha;
    /**
     * 
     * @type {AggregationAtocha}
     * @memberof Aggregation
     */
    'pmet'?: AggregationAtocha;
    /**
     * 
     * @type {AggregationAtocha}
     * @memberof Aggregation
     */
    'heatmap'?: AggregationAtocha;
}
/**
 * 
 * @export
 * @interface AggregationAtocha
 */
export interface AggregationAtocha {
    /**
     * 
     * @type {string}
     * @memberof AggregationAtocha
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationAtocha
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface Apollo
 */
export interface Apollo {
    /**
     * 
     * @type {string}
     * @memberof Apollo
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof Apollo
     */
    'created_at': string;
    /**
     * 
     * @type {ApolloProperties}
     * @memberof Apollo
     */
    'properties': ApolloProperties;
}
/**
 * 
 * @export
 * @interface ApolloProperties
 */
export interface ApolloProperties {
    /**
     * 
     * @type {string}
     * @memberof ApolloProperties
     */
    'Deployment ID': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApolloProperties
     */
    'Links': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApolloProperties
     */
    'Description': string;
    /**
     * 
     * @type {string}
     * @memberof ApolloProperties
     */
    'CTI': string;
    /**
     * 
     * @type {string}
     * @memberof ApolloProperties
     */
    'Team': string;
}
/**
 * 
 * @export
 * @interface CreateTicket
 */
export interface CreateTicket {
    /**
     * 
     * @type {string}
     * @memberof CreateTicket
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicket
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicket
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicket
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicket
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicket
     */
    'severity': string;
}
/**
 * 
 * @export
 * @interface Lse
 */
export interface Lse {
    /**
     * 
     * @type {string}
     * @memberof Lse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof Lse
     */
    'end': string;
    /**
     * 
     * @type {LseProperties}
     * @memberof Lse
     */
    'properties': LseProperties;
}
/**
 * 
 * @export
 * @interface LseProperties
 */
export interface LseProperties {
    /**
     * 
     * @type {string}
     * @memberof LseProperties
     */
    'Impact': string;
    /**
     * 
     * @type {string}
     * @memberof LseProperties
     */
    'Status': string;
    /**
     * 
     * @type {string}
     * @memberof LseProperties
     */
    'Description': string;
    /**
     * 
     * @type {string}
     * @memberof LseProperties
     */
    'Link': string;
    /**
     * 
     * @type {string}
     * @memberof LseProperties
     */
    'Symptoms': string;
}
/**
 * 
 * @export
 * @interface MCM
 */
export interface MCM {
    /**
     * 
     * @type {string}
     * @memberof MCM
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof MCM
     */
    'created_at': string;
    /**
     * 
     * @type {MCMProperties}
     * @memberof MCM
     */
    'properties': MCMProperties;
}
/**
 * 
 * @export
 * @interface MCMProperties
 */
export interface MCMProperties {
    /**
     * 
     * @type {string}
     * @memberof MCMProperties
     */
    'URL': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MCMProperties
     */
    'Region': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MCMProperties
     */
    'Description': string;
    /**
     * 
     * @type {string}
     * @memberof MCMProperties
     */
    'CTI': string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface SevTwo
 */
export interface SevTwo {
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'impact': string;
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'isHse': string;
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'serviceName': string;
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'assignedGroup': string;
    /**
     * 
     * @type {string}
     * @memberof SevTwo
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'sourceType': string;
    /**
     * 
     * @type {TicketCompressedData}
     * @memberof Ticket
     */
    'compressedData': TicketCompressedData;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'conversationLink': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'createdDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'updatedDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'primaryServiceName': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'igraph_metric_name': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'igraphLink': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Ticket
     */
    'recommendedServices': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Ticket
     */
    'recommendedRootCauses': Array<string>;
}
/**
 * 
 * @export
 * @interface TicketCompressedData
 */
export interface TicketCompressedData {
    /**
     * 
     * @type {TicketCompressedDataPrimaryServices}
     * @memberof TicketCompressedData
     */
    'primary_services': TicketCompressedDataPrimaryServices;
    /**
     * 
     * @type {TicketCompressedDataDependencyServices}
     * @memberof TicketCompressedData
     */
    'dependency_services': TicketCompressedDataDependencyServices;
}
/**
 * 
 * @export
 * @interface TicketCompressedDataDependencyServices
 */
export interface TicketCompressedDataDependencyServices {
    /**
     * 
     * @type {Array<TicketCompressedDataDependencyServicesItems>}
     * @memberof TicketCompressedDataDependencyServices
     */
    'items': Array<TicketCompressedDataDependencyServicesItems>;
}
/**
 * 
 * @export
 * @interface TicketCompressedDataDependencyServicesItems
 */
export interface TicketCompressedDataDependencyServicesItems {
    /**
     * 
     * @type {string}
     * @memberof TicketCompressedDataDependencyServicesItems
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface TicketCompressedDataPrimaryServices
 */
export interface TicketCompressedDataPrimaryServices {
    /**
     * 
     * @type {Array<TicketCompressedDataPrimaryServicesItems>}
     * @memberof TicketCompressedDataPrimaryServices
     */
    'items': Array<TicketCompressedDataPrimaryServicesItems>;
}
/**
 * 
 * @export
 * @interface TicketCompressedDataPrimaryServicesItems
 */
export interface TicketCompressedDataPrimaryServicesItems {
    /**
     * 
     * @type {number}
     * @memberof TicketCompressedDataPrimaryServicesItems
     */
    'updated_at': number;
}
/**
 * 
 * @export
 * @interface UsersEngaged
 */
export interface UsersEngaged {
    /**
     * 
     * @type {string}
     * @memberof UsersEngaged
     */
    'ticket': string;
    /**
     * 
     * @type {string}
     * @memberof UsersEngaged
     */
    'user': string;
}
/**
 * 
 * @export
 * @interface Weblab
 */
export interface Weblab {
    /**
     * 
     * @type {string}
     * @memberof Weblab
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof Weblab
     */
    'created_at': string;
    /**
     * 
     * @type {WeblabProperties}
     * @memberof Weblab
     */
    'properties': WeblabProperties;
}
/**
 * 
 * @export
 * @interface WeblabProperties
 */
export interface WeblabProperties {
    /**
     * 
     * @type {string}
     * @memberof WeblabProperties
     */
    'Location': string;
    /**
     * 
     * @type {string}
     * @memberof WeblabProperties
     */
    'Weblab Name': string;
    /**
     * 
     * @type {string}
     * @memberof WeblabProperties
     */
    'Description': string;
    /**
     * 
     * @type {string}
     * @memberof WeblabProperties
     */
    'CTI': string;
    /**
     * 
     * @type {string}
     * @memberof WeblabProperties
     */
    'Obfuscated Marketplace ID': string;
}
/**
 * 
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'FolderId': string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'WorkflowName': string;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    'IsWorkflowActive': boolean;
}

/**
 * TicketsApi - axios parameter creator
 * @export
 */
export const TicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create ticket
         * @param {CreateTicket} createTicket Ticket creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: async (createTicket: CreateTicket, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTicket' is not null or undefined
            assertParamExists('createTicket', 'createTicket', createTicket)
            const localVarPath = `/ticket/cutticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTicket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Engage the user to the ticket
         * @param {UsersEngaged} usersEngaged A user name to be paged
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engageuserById: async (usersEngaged: UsersEngaged, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersEngaged' is not null or undefined
            assertParamExists('engageuserById', 'usersEngaged', usersEngaged)
            const localVarPath = `/tickets/ticketId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersEngaged, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ticket Aggregation like atocha, heatmap etc
         * @param {string} ticketId The id of the ticket whose attached aggregated information are to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregationsByTicketId: async (ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getAggregationsByTicketId', 'ticketId', ticketId)
            const localVarPath = `/aggregations/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workflows associated with specific ticket
         * @param {string} ticketId The id of the ticket whose attached workflows are to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowsByTicketId: async (ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getWorkflowsByTicketId', 'ticketId', ticketId)
            const localVarPath = `/workflows/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all tickets
         * @param {string} [startDate] filter Hse\&#39;s by start date
         * @param {string} [endDate] filter Hse\&#39;s by end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTickets: async (startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get datasource details for a ticket
         * @param {string} ticketId The id of the ticket to retrieve
         * @param {string} dataSource dataSource name
         * @param {string} dataSourceType dataSource type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showDataSourceDetails: async (ticketId: string, dataSource: string, dataSourceType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('showDataSourceDetails', 'ticketId', ticketId)
            // verify required parameter 'dataSource' is not null or undefined
            assertParamExists('showDataSourceDetails', 'dataSource', dataSource)
            // verify required parameter 'dataSourceType' is not null or undefined
            assertParamExists('showDataSourceDetails', 'dataSourceType', dataSourceType)
            const localVarPath = `/tickets/{ticketId}/{dataSource}/{dataSourceType}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)))
                .replace(`{${"dataSource"}}`, encodeURIComponent(String(dataSource)))
                .replace(`{${"dataSourceType"}}`, encodeURIComponent(String(dataSourceType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific ticket detail
         * @param {string} ticketId The id of the ticket to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTicketById: async (ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('showTicketById', 'ticketId', ticketId)
            const localVarPath = `/tickets/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketsApi - functional programming interface
 * @export
 */
export const TicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create ticket
         * @param {CreateTicket} createTicket Ticket creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicket(createTicket: CreateTicket, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicket(createTicket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Engage the user to the ticket
         * @param {UsersEngaged} usersEngaged A user name to be paged
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engageuserById(usersEngaged: UsersEngaged, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersEngaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.engageuserById(usersEngaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Ticket Aggregation like atocha, heatmap etc
         * @param {string} ticketId The id of the ticket whose attached aggregated information are to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregationsByTicketId(ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Aggregation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregationsByTicketId(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get workflows associated with specific ticket
         * @param {string} ticketId The id of the ticket whose attached workflows are to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowsByTicketId(ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Workflow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowsByTicketId(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all tickets
         * @param {string} [startDate] filter Hse\&#39;s by start date
         * @param {string} [endDate] filter Hse\&#39;s by end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTickets(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTickets(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get datasource details for a ticket
         * @param {string} ticketId The id of the ticket to retrieve
         * @param {string} dataSource dataSource name
         * @param {string} dataSourceType dataSource type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showDataSourceDetails(ticketId: string, dataSource: string, dataSourceType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Apollo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showDataSourceDetails(ticketId, dataSource, dataSourceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific ticket detail
         * @param {string} ticketId The id of the ticket to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showTicketById(ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ticket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showTicketById(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketsApi - factory interface
 * @export
 */
export const TicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create ticket
         * @param {CreateTicket} createTicket Ticket creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket(createTicket: CreateTicket, options?: any): AxiosPromise<CreateTicket> {
            return localVarFp.createTicket(createTicket, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Engage the user to the ticket
         * @param {UsersEngaged} usersEngaged A user name to be paged
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engageuserById(usersEngaged: UsersEngaged, options?: any): AxiosPromise<UsersEngaged> {
            return localVarFp.engageuserById(usersEngaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ticket Aggregation like atocha, heatmap etc
         * @param {string} ticketId The id of the ticket whose attached aggregated information are to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregationsByTicketId(ticketId: string, options?: any): AxiosPromise<Aggregation> {
            return localVarFp.getAggregationsByTicketId(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workflows associated with specific ticket
         * @param {string} ticketId The id of the ticket whose attached workflows are to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowsByTicketId(ticketId: string, options?: any): AxiosPromise<Array<Workflow>> {
            return localVarFp.getWorkflowsByTicketId(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all tickets
         * @param {string} [startDate] filter Hse\&#39;s by start date
         * @param {string} [endDate] filter Hse\&#39;s by end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTickets(startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.listTickets(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get datasource details for a ticket
         * @param {string} ticketId The id of the ticket to retrieve
         * @param {string} dataSource dataSource name
         * @param {string} dataSourceType dataSource type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showDataSourceDetails(ticketId: string, dataSource: string, dataSourceType: string, options?: any): AxiosPromise<Apollo> {
            return localVarFp.showDataSourceDetails(ticketId, dataSource, dataSourceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific ticket detail
         * @param {string} ticketId The id of the ticket to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTicketById(ticketId: string, options?: any): AxiosPromise<Ticket> {
            return localVarFp.showTicketById(ticketId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketsApi - object-oriented interface
 * @export
 * @class TicketsApi
 * @extends {BaseAPI}
 */
export class TicketsApi extends BaseAPI {
    /**
     * 
     * @summary Create ticket
     * @param {CreateTicket} createTicket Ticket creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public createTicket(createTicket: CreateTicket, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).createTicket(createTicket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Engage the user to the ticket
     * @param {UsersEngaged} usersEngaged A user name to be paged
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public engageuserById(usersEngaged: UsersEngaged, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).engageuserById(usersEngaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ticket Aggregation like atocha, heatmap etc
     * @param {string} ticketId The id of the ticket whose attached aggregated information are to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getAggregationsByTicketId(ticketId: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getAggregationsByTicketId(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workflows associated with specific ticket
     * @param {string} ticketId The id of the ticket whose attached workflows are to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getWorkflowsByTicketId(ticketId: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getWorkflowsByTicketId(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all tickets
     * @param {string} [startDate] filter Hse\&#39;s by start date
     * @param {string} [endDate] filter Hse\&#39;s by end date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public listTickets(startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).listTickets(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get datasource details for a ticket
     * @param {string} ticketId The id of the ticket to retrieve
     * @param {string} dataSource dataSource name
     * @param {string} dataSourceType dataSource type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public showDataSourceDetails(ticketId: string, dataSource: string, dataSourceType: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).showDataSourceDetails(ticketId, dataSource, dataSourceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific ticket detail
     * @param {string} ticketId The id of the ticket to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public showTicketById(ticketId: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).showTicketById(ticketId, options).then((request) => request(this.axios, this.basePath));
    }
}


