import React from "react";
import {Route, Switch} from "react-router-dom";
import DashboardHome from "./dashboard/DashboardHome";
import MetricsComponent from "./metrics/MetricsComponent"
import SourceTypeHome from "./dataSourceDetails/Common/SourceTypeHome";
import TicketsListViewHome from "./ticketsListView/TicketsListViewHome";
import LseEvents from "./dataSourceDetails/Lse";
import MetricDetails from "./dataSourceDetails/Common/MetricsDetails";
import ApolloDetails from "./dataSourceDetails/ApolloDetails";
import McmDetails from "./dataSourceDetails/McmDetails";
import SevTwoDetails from "./dataSourceDetails/Sev2Details";
import WeblabDetails from "./dataSourceDetails/WeblabDetails";

export default function App() {
    return (
        <Switch>
            <Route exact path={"/metrics/:metric/:urlId"}
                   render={(props) => {
                    return <MetricsComponent
                        metricType={props.match.params.metric}
                        urlId={props.match.params.urlId}
                    />
                   }}
            />
            <Route exact path="/" component={TicketsListViewHome}/>
            <Route exact path="/:ticketId" component={DashboardHome}/>
            <Route
                exact
                path="/:ticketId/:dataSource"
                render={(props) => {
                    switch (props.match.params.dataSource) {
                        case "APOLLO":
                            return <SourceTypeHome/>;
                        case "MCM":
                            return <SourceTypeHome/>;
                        case "WEBLABS":
                            return <SourceTypeHome/>;
                        case "SEV2":
                            return <SourceTypeHome/>;
                        case "LSE":
                            return <LseEvents/>;
                        case "THROTTLING":
                            return <MetricDetails/>;
                        case "CPU_UTILISATION":
                            return <MetricDetails/>;
                        case "DISK_USAGE":
                            return <MetricDetails/>;
                        case "TRAFFIC_SURGE":
                            return <MetricDetails/>;
                        case "VIP_SPILLOVERS":
                            return <MetricDetails/>;
                        case "MEM_ACTIVE":
                            return <MetricDetails/>;
                    }
                }}
            />
            <Route
                exact
                path="/:ticketId/:dataSource/:dataSourceType"
                render={(props) => {
                    switch (props.match.params.dataSource) {
                        case "APOLLO":
                            return <ApolloDetails/>;
                        case "MCM":
                            return <McmDetails/>;
                        case "WEBLABS":
                            return <WeblabDetails/>;
                        case "SEV2":
                            return <SevTwoDetails/>;
                    }
                }}
            />
        </Switch>
    );
}
