import React from "react";
import { useState } from "react";
import MarkdownIt from "markdown-it";
import {
  Button,
  ColumnLayout,
  Container,
  Toggle,
} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import Icon from "@amzn/awsui-components-react/polaris/icon";

export const PostComment = () => {
  const [markdownText, setMarkDownText] = useState("");
  const [renderedHTML, setRenderedHTML] = useState("");
  const [markDownEnabled, setMarkDownEnabled] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const handleTextInput = (e) => {
    setMarkDownText(e.target.value);

    let md = new MarkdownIt();
    let renderedHTML = md.render(e.target.value);

    setRenderedHTML(renderedHTML);
  };
  return (
    <Container
      header={
        <Header variant="h2" description="">
          Post To Correspondence
        </Header>
      }
    >
      <div>Add Comment</div>
      <textarea
        className="form-control markdown-textarea"
        rows={3}
        value={markdownText}
        onChange={handleTextInput}
      ></textarea>
      <div id="commentParentDiv">
        <div>
          <Toggle
            onChange={({ detail }) => setMarkDownEnabled(!markDownEnabled)}
            checked={markDownEnabled}
          >
            Markdown
          </Toggle>
        </div>
        <div id="commentBtn">
          {" "}
          <Button
            variant="primary"
            onClick={() => {
              setSubmitButtonClicked(true);
            }}
          >
            Comment
          </Button>
        </div>
      </div>
      {submitButtonClicked ? (
        <div id="successDiv">
          {" "}
          <Icon name="status-positive" variant="success" />
          Comment posted to ticket correspondence
        </div>
      ) : (
        <></>
      )}
      <div className="card-body">
        {markDownEnabled ? (
          <div
            dangerouslySetInnerHTML={{ __html: renderedHTML }}
            className="rendered-html-output"
          />
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
};
