import {Badge, Container, Link} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import React, {useContext} from "react";
import {Workflow} from "../../tickets-api/generated-src";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import "./WorkflowsExecuted.scss";

export default function WorkflowsExecuted(props) {
    let allWorkflows: Workflow[] = props.data || [];
    let workflows: string[] = [];
    allWorkflows.forEach((workflow) => {
        if (workflow.IsWorkflowActive) {
            let workflowName = workflow.WorkflowName.split("#")[0]
                .replace("DWF_", "")
                .replace("_", " ");
            workflows.push(workflowName);
        }
    });
    const handleEvents = (allActiveWorkflowNames) => {
        if (allActiveWorkflowNames.length > 0) {
            return allActiveWorkflowNames.map((workflowName) => (
                <div style={{margin: "10px"}}>
                    <Badge color="green">{workflowName}</Badge>
                </div>
            ));
        }
        return (
            <div style={{margin: "auto"}}>
                <Spinner size="normal"/>
            </div>
        );
    };
    return (
        <>
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Below mentioned are the workflows executed for aforementioned ticket Id"
                    >
                        Workflows Executed (Please click{" "}
                        <Link
                            external
                            externalIconAriaLabel="Opens in a new tab"
                            href="https://demworkflow.axes.amazon.dev/#/"
                        >
                            here
                        </Link>{" "}
                        to configure more.)
                    </Header>
                }
            >
                <div className={"workflow-executed"}>
                    {handleEvents(workflows)}
                    {props.showAtochaworkFlow? <div style={{margin: "10px"}}>
                        <Badge color="green">DWF_APOLLO_DEPLOYMENT</Badge>
                    </div> : <></>}
                </div>
            </Container>
        </>
    );
}
