import React, {useEffect} from "react";
import {
    Button,
    Input,
    Modal,
    ProgressBar,
    Select,
    SelectProps,
    Toggle,
} from "@amzn/awsui-components-react";
import "./MitigationLeverStyles.scss";
import Icon from "@amzn/awsui-components-react/polaris/icon";


/**
 * Component to render Mitigation Levers
 */

var interval = 0;
export default function MitigationLevers(props) {
    const [throttlingChecked, setThrottlingChecked] = React.useState(false);
    const [autoScalingChecked, setAutoScalingChecked] = React.useState(false);
    const [rollbackDeploymentsChecked, setRollbackDeploymentsChecked] =
        React.useState(false);

    const [throttlingLimit, setThrottlingLimit] = React.useState("");
    const [deploymentVFI, setDeploymentVFI] = React.useState<SelectProps.Option>({
        label: "",
        value: "",
    });
    const [hostType, setSelectedHostType] = React.useState<SelectProps.Option>({
        label: "",
        value: "",
    });
    const [approver, setApprover] = React.useState("");
    const [rollbackReason, setRollbackReason] = React.useState("");
    const [submitThrottlingClicked, setThrottlingSubmitClicked] =
        React.useState(false);
    const [submitAutoScaledClicked, setAutoScaledSubmitClicked] =
        React.useState(false);
    const [submitRollbackDeploymentClicked, setRollbackDeploymentSubmitClicked] =
        React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [progressBarRunning, setProgressBarRunning] = React.useState(false);
    const updateWorkFlows=props.updateWorkFlows;

    useEffect(() => {
        if (progressBarRunning) {
            interval = setInterval(() => {
                setProgress((prev) => prev + 1);
            }, 200);
        } else {
            clearInterval(interval);
        }
    }, [progressBarRunning]);

    useEffect(() => {
        if (progress === 100) {
            setProgressBarRunning(false);
            clearInterval(interval);
        }
    }, [progress]);

    /**
     * Component for showing success message on clicking Submit
     */
    const submitSuccess = () => {
        if (rollbackDeploymentsChecked) {
            updateWorkFlows();
            return (
                <div id="sucessDiv">
                    {progress === 100 ? (
                        <span>
              <Icon name="status-positive" variant="success" />
              <span id="successText">VFI deployed Successfully, Status updated in the ticket</span>
            </span>
                    ) : (
                        <></>
                    )}
                    {progressBarRunning ? (
                        <ProgressBar
                            value={progress}
                            additionalInfo=""
                            description="Deploying VFI, DWF_Apollo_Deployment excuting"
                            label=""
                        />
                    ) : (
                        <></>
                    )}
                </div>
            );
        } else if (submitAutoScaledClicked) {
            return (
                <div id="sucessDiv">
          <span>
            <Icon name="status-positive" variant="success" />
            <span id="successText">Hosts Ordered Successfully</span>
          </span>
                </div>
            );
        } else if (submitThrottlingClicked) {
            return (
                <div id="sucessDiv">
          <span>
            <Icon name="status-positive" variant="success" />
            <span id="successText">Request Sent For Approval</span>
          </span>
                </div>
            );
        }
    };

    /**
     * Input form for Throttling
     */
    const throttlingContent = () => {
        return (
            <div>
                <div id="textDescription">
                    Requests being throttled from Customer ID{" "}
                </div>
                <div>A2Y92PCUL5NYE9</div>
                <div id="textDescription">
                    Current throttling value from AlexaCapabilitiesRuntimeService
                </div>
                <div>250</div>
                <div id="textDescription">
                    Update throttling config value <span id="asterick">*</span>
                </div>
                <Input
                    key="ThrottlingLimit"
                    onChange={(event) => setThrottlingLimit(event.detail.value)}
                    value={throttlingLimit}
                    placeholder="5"
                    type="number"
                />
                <div id="textDescription">
                    Approver <span id="asterick">*</span>
                </div>
                <Input
                    key="ThrottlingApprover" // <= this is the solution to prevent re-render
                    value={approver}
                    type="text"
                    onChange={(event) => setApprover(event.detail.value)}
                />
                <br />
                <span>
          <Button
              variant="primary"
              onClick={() => {
                  setThrottlingSubmitClicked(!submitThrottlingClicked);
              }}
          >
            Submit
          </Button>
                    {submitThrottlingClicked ? submitSuccess() : <></>}
        </span>
            </div>
        );
    };

    /**
     * Inout form for Auto-Scaling
     */
    const autoScalingContent = () => {
        return (
            <div>
                <div id="textDescription">Current infrastructure</div>
                <div>m4.xlarge</div>
                <div id="textDescription">
                    Recommended host <span id="asterick">*</span>
                </div>
                <Select
                    selectedOption={hostType}
                    onChange={({ detail }) => setSelectedHostType(detail.selectedOption)}
                    options={[
                        { label: "m5.xlarge", value: "m5.xlarge" },
                        { label: "r5.large", value: "r5.large" },
                    ]}
                    selectedAriaLabel="Selected"
                />
                <div id="textDescription">
                    Approver <span id="asterick">*</span>
                </div>
                <Input
                    onChange={({ detail }) => setApprover(detail.value)}
                    value={approver}
                    type="text"
                />
                <br />
                <span>
          <Button
              variant="primary"
              onClick={() => {
                  setAutoScaledSubmitClicked(!submitAutoScaledClicked);
              }}
          >
            Submit
          </Button>
                    {submitAutoScaledClicked ? submitSuccess() : <></>}
        </span>
            </div>
        );
    };

    /**
     * Input form for Rollback Deployments
     */
    const rollbackContent = () => {
        return (
            <div>
                <div id="textDescription">
                    Select deployment to rollback <span id="asterick">*</span>
                </div>
                <Select
                    selectedOption={deploymentVFI}
                    onChange={({ detail }) => setDeploymentVFI(detail.selectedOption)}
                    options={[
                        {
                            label:
                                "AlexaCapabilitiesRuntimeService/tpstesting@B6120212718-AL2_x86_64",
                            value:
                                "AlexaCapabilitiesRuntimeService/tpstesting@B6120212718-AL2_x86_64",
                        },
                        {
                            label:
                                "AlexaCapabilitiesRuntimeService/FE@B8125958452-AL2_x86_64",
                            value:
                                "AlexaCapabilitiesRuntimeService/FE@B8125958452-AL2_x86_64",
                        },
                        {
                            label:
                                "AlexaCapabilitiesRuntimeService/EU@B6125958452-AL2_x86_64",
                            value:
                                "AlexaCapabilitiesRuntimeService/EU@B6125958452-AL2_x86_64",
                        },
                        {
                            label:
                                "AlexaCapabilitiesRuntimeService/NA@B6125958452-AL2_x86_64",
                            value:
                                "AlexaCapabilitiesRuntimeService/NA@B6125958452-AL2_x86_64",
                        },
                        {
                            label:
                                "AlexaCapabilitiesRuntimeService/NA@AX162Y38U2J-AL2_x86_64",
                            value:
                                "AlexaCapabilitiesRuntimeService/NA@AX162Y38U2J-AL2_x86_64",
                        },
                    ]}
                    selectedAriaLabel="Selected"
                />
                <div id="textDescription">
                    Reason for rollback <span id="asterick">*</span>
                </div>
                <Input
                    onChange={({ detail }) => setRollbackReason(detail.value)}
                    value={rollbackReason}
                    type="text"
                />
                <div id="textDescription">
                    Approver <span id="asterick">*</span>
                </div>
                <Input
                    onChange={({ detail }) => setApprover(detail.value)}
                    value={approver}
                    type="text"
                />
                <br />
                <span>
          <Button
              variant="primary"
              onClick={() => {
                  setRollbackDeploymentSubmitClicked(
                      !submitRollbackDeploymentClicked
                  );
                  setProgressBarRunning(!progressBarRunning);
              }}
          >
            Submit
          </Button>
                    {submitRollbackDeploymentClicked ? submitSuccess() : <></>}
        </span>
            </div>
        );
    };

    return (
        <>
            <Toggle
                onChange={({ detail }) => setThrottlingChecked(!throttlingChecked)}
                checked={throttlingChecked}
            >
                Adjust Throttling
            </Toggle>
            <Toggle
                onChange={({ detail }) => setAutoScalingChecked(!autoScalingChecked)}
                checked={autoScalingChecked}
            >
                Scale Infrastructure
            </Toggle>
            <Toggle
                onChange={({ detail }) =>
                    setRollbackDeploymentsChecked(!rollbackDeploymentsChecked)
                }
                checked={rollbackDeploymentsChecked}
            >
                Rollback Deployments
            </Toggle>
            <br />
            <Modal
                onDismiss={() => {
                    setThrottlingChecked(false);
                    setApprover("");
                    setThrottlingLimit("");
                    setThrottlingSubmitClicked(false);
                }}
                visible={throttlingChecked}
                closeAriaLabel="Close modal"
                header="Throttling"
            >
                {throttlingContent()}
            </Modal>
            <Modal
                onDismiss={() => {
                    setAutoScalingChecked(false);
                    setApprover("");
                    setSelectedHostType({ label: "", value: "" });
                    setAutoScaledSubmitClicked(false);
                }}
                visible={autoScalingChecked}
                closeAriaLabel="Close modal"
                header="Scale Infrastructure"
            >
                {autoScalingContent()}
            </Modal>
            <Modal
                onDismiss={() => {
                    setRollbackDeploymentsChecked(false);
                    setApprover("");
                    setRollbackDeploymentSubmitClicked(false);
                    setRollbackReason("");
                    setDeploymentVFI({ label: "", value: "" });
                }}
                visible={rollbackDeploymentsChecked}
                closeAriaLabel="Close modal"
                header="Rollback Deployments"
            >
                {rollbackContent()}
            </Modal>
        </>
    );
}