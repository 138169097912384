import React, {useEffect} from "react";

export default function MetricsComponent(props) {

    useEffect(() => {
        console.log("Adding " + props.metricType + " metrics by 1")
        window.location.replace('https://tiny.amazon.com/' + props.urlId);
    }, []);

    return <>Redirecting...</>;
}